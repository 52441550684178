<template>

  <component
    :is="scaleFactTemplate"
    :data="mediumFactValue"
    :disabled="disabled"
    v-loading="loading"
    @change="saveMediumFact"
  ></component>

</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";

export default {
  name: 'element-medium-fact-period',
  components: {ClickToEdit},

  props: {
    period: {type: Number, required: true},
    scope: {type: Object, required: true},
    disabled: {type: Boolean, default: false},
  },

  computed: {
    scaleFactTemplate() {
      if (!this.scope.row.scale_type) {
        return null;
      }
      return this.$companyConfiguration.scales.getScaleTemplate(this.scope.row.scale_type, 'cardFactView');
    },
    mediumFactValue() {
      return {fact: this.scope.row.medium_facts.find(mf => mf.period === this.period)?.value};
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    saveMediumFact(newValue) {
      this.loading = true;
      this.$api.card.saveElementMediumFact({
        element_id: this.scope.row.id,
        period: this.period,
        value: newValue,
      })
        .then((data) => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Факт успешно сохранен'
          });
        })
        .finally(() => {
          this.$emit('elements-changed');
          this.loading = false;
        })
    }
  }
}

</script>

<style>

</style>
